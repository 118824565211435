import styled from '@emotion/styled';

export const WrapperStep = styled.section`
  // overflow: ${props => (props.isAnimating ? 'hidden' : 'visible')};
  height: ${props => (props.isAnimating ? '400px' : 'auto')};
  position: relative;
  z-index: 1;
  transform: translateZ(0);
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Contenedor del modal
export const ContainerModal = styled.div`
  background: #fff;
  border-radius: 3px;
  width: 339.4px;
  max-height: 445px;
  padding: 26px 13px;
  position: fixed;
  top: 1px;
`;

export const SectionModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContenedorIconos = styled.div`
  margin-bottom: 15px;
`;

export const Icono = styled.img`
  width: 50px;
  height: 50px;
`;

export const IconoCerrar = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #333;
    transform: scale(1.1);
  }
`;

export const TituloModal = styled.h2`
  font-size: 15px;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 20px;
  width: 285px;
  color: #000;
  height: 46px;
  font-family: 'Poppins', sans-serif;
`;

export const ContenedorConfronta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContenedorPregunta = styled.div`
  width: 100%;
`;

export const Pregunta = styled.h2`
  font-size: 13.5px;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
`;

export const ContenedorRespuestas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px; 
  margin: 20px 0;
  width: 100%;
`;

export const RadioOption = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + span::after {
    opacity: 1;
  }
`;

export const RadioCircle = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #666;
  margin-right: 10px;
  position: relative;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #007bff;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
`;

export const RadioLabel = styled.span`
  font-size: 13.4px;
  color: #000; /* Color negro como en Figma */
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
`;

export const Boton = styled.button`
  width: 100%;
  height: 46px;
  background-color: #059cf4;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0487d9; /* Un tono más oscuro al hacer hover */
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;